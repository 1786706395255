<template>
  <div class="li-info">
    <div v-if="info" class="info-content">
      <div class="info-title">
        <div class="info-name">{{ info.activity_name }}</div>
        <div class="info-status">{{ info.status | transitionStatus }}</div>
      </div>
      <div class="info-con">
        <div class="info-peoples">
          <div v-for="(p, z) in info.user_list" :key="z" class="people-info"><img :src="p.litpic" alt=""></div>
          <div v-if="info.surplus_ct && info.status === 0" class="lost">还差{{ info.surplus_ct }}人</div>
        </div>
        <div class="info-time">
          <span v-if="time">距离结束{{ `${time.h}:${time.m}:${time.s}` }}</span>
          <span v-else>{{ info.created_at }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { countTime } from '@/utils/public'
import lostIcon from '@/assets/images/activity/divide/lost_people.png'

export default {
  name: 'PRecordLi',
  filters: {
    transitionStatus(status) {
      switch (status) {
        case 0: return '成团中'
        case 1: return '已成团'
        case 2: return '成团失败'
        default: return ''
      }
    }
  },
  props: {
    info: {
      type: null,
      default() {
        return {
          created_at: '',
          tuan_endtime: ''
        }
      }
    }
  },
  data() {
    return {
      lostIcon,
      timer: null,
      time: null
    }
  },
  watch: {
    info() {
      this.handleTransitionTime()
    }
  },
  mounted() {
    this.handleTransitionTime()
  },
  beforeDestroy() {
    const { timer } = this

    if (timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    /**
     * 处理倒计时时间转化
     * 2021-05-30 16:23:45
     * @author SnowRock
     */
    handleTransitionTime() {
      const tuan_endtime = this.info.tuan_endtime
      if (tuan_endtime && tuan_endtime !== '') {
        if (tuan_endtime - (new Date().getTime() / 1000) <= 86400) {
          this.countTime(tuan_endtime)
        } else {
          this.time = null
          this.timer = null
        }
      }
    },
    countTime(endTime) {
      const vm = this
      if (vm.info.status !== 0 || endTime <= (new Date().getTime() / 1000)) {
        if (vm.timer) {
          clearInterval(vm.timer)
          vm.timer = null
        }
      } else {
        vm.time = countTime(endTime)

        vm.timer = setTimeout(() => {
          vm.countTime(endTime)
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>

</style>
