<template>
  <div class="participation-record">
    <van-tabs v-model="active" title-active-color="#F7263C" color="#F7263C" @change="onRefresh">
      <van-tab title="全部" :name="1" />
      <van-tab title="我发起的" :name="2" />
      <van-tab title="我参与的" :name="3" />
    </van-tabs>

    <!-- 活动记录 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-if="list.length > 0" class="list-content">
          <div v-for="(i, j) in list" :key="j" @click="handleNavTo(i)">
            <p-record-li :info="i" />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh, Tab, Tabs } from 'vant'
import Vue from 'vue'
import { fetchRewardRecordList } from '@/services/activity/divide-bonus'
import PRecordLi from '@/views/activity/divide-bonus-activity/components/PRecordLi'

Vue.use(Tabs).use(Tab).use(List).use(PullRefresh)

export default {
  name: 'ParticipationRecord',
  components: { PRecordLi },
  data() {
    return {
      active: 1,
      finished: false,
      loading: false,
      refreshing: false,
      error: false,
      page: 0,
      list: []
    }
  },

  methods: {

    onLoad() {
      this.page++
      const params = {
        type: this.active, // （1 全部、2 我发起的、3我参与的）
        page: this.page,
        pagesize: 10
      }
      fetchRewardRecordList(params).then(res => {
        const { code, data } = res
        this.loading = false
        if (code === 200) {
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          let ls = this.list
          ls = ls.concat(data.list)

          this.list = ls
          this.finished = data.list < 10
        } else {
          this.error = true
          this.page--
        }
      }).catch(() => {
        this.loading = false
        this.error = true
        this.page--
      })
    },
    /**
     * 下拉刷新
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.list = []
      this.page = 0

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    /**
     * 点击跳转至活动首页
     * 2021-05-25 17:52:03
     * @author SnowRock
     */
    handleNavTo({ group_id, id }) {
      this.$router.push({
        path: '/DivideBonusActivity',
        query: {
          id: id,
          groupId: group_id
        }
      })
    }
  }
}
</script>

<style lang="less">
.participation-record {
  min-height: 100vh;
  background-color: #F8F8F8;

  .list-content {
    width: calc(100% - 30px);
    margin: 10px auto;
    box-sizing: border-box;
    padding: 5px 16px;
    background-color: #fff;
    &>div:last-of-type {
      .li-info {
        border: none;
      }
    }
    .li-info {
      padding: 15px 0;
      border-bottom: 1px solid #f2f2f2;

      .info-title {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        font-family: PingFang SC, sans-serif;
        justify-content: space-between;

        .info-name {
          color: #000000;
        }

        .info-status {
          color: #f7263c;
        }
      }

      .info-con {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info-peoples {
          display: flex;
          align-items: center;

          .people-info {
            width: 23px;
            height: 23px;
            margin-right: 5px;
            border-radius: 50%;
            overflow: hidden;
          }
          .lost {
            color: #F7263C;
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .info-time {
          font-size: 13px;
          line-height: 16px;
          color: #666666;
        }
      }
    }
  }
}
</style>
